import ky from "ky";

export const sendUserStatusToServer = async (user, isActive) => {
  const searchParams = new URLSearchParams();
  searchParams.set("intent", "sent-status-user");
  searchParams.set("uuid", user.uuid);
  searchParams.set("id", user.id);
  searchParams.set("active", isActive);
  searchParams.set("updated", new Date().toISOString());
  const status = isActive ? "online" : "offline";

  const data = {
    id: user?.uuid,
    name: user?.fullName,
    externalId: user?.id,
    profileUrl: `/profile/${user?.id}`,
    email: user?.email,
    status,
  };

  try {
    const res = await ky.get(`/resources/post-by-get?${searchParams.toString()}`).json();
    // await ky.post("/resources/friend", {
    //   body: formData,
    // });
    // await ky.patch(
    //   `https://ps.pndsn.com/v2/objects/${user?.subscribeKey}/uuids/${user?.uuid}?include=status`,
    //   {
    //     json: data,
    //   }
    // );
    // console.log(`User ${user.id} status updated to ${isActive ? "active" : "inactive"}`);
  } catch (error) {
    console.error("Failed to send user status to server:", error);
  }
};

export const sendViewsToServer = async (json) => {
  const formData = new FormData();
  formData.append("json", json);

  try {
    await ky.post("/resources/feeds", {
      body: formData,
    });
  } catch (error) {
    console.error("Failed to send user status to server:", error);
  }
};
